// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  region: 'NA',
  appUrl: 'https://posted-speed.dev.zonarsystems.net/',

  apiBase: {
    url: 'https://posted-speed-api.dev.zonarsystems.net/api/v1'
  },

  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net'
  },

  geocoderApi: {
    url: 'https://zonar-nonprod-dev.apigee.net'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'development',

  auth: {
    clientID: 'zXn9dEOvlVGujxRe8EKvjZXFIrEJgwBA',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: '941783c3-bb57-4bd5-906d-2d0dfeb91f61',
    defaultZonarRole: '80b69fb9-c13c-4efb-9103-40c5dd3dd9a0',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_d1c490e2_32bf_4c4f_90ba_7ba8b900d507_ec15da3'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: '0682e73f-9602-4954-96f0-910ca3a27c62',
    clientToken: 'pubc05d7f578e8d6c9ada0fda6eeb446fe1',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'dev'
  },

  i18n: {
    supportedLanguages: [
      'en-US',
      'en-CA',
      'de-DE',
      'en-GB',
      'fr-FR',
      'es-ES',
      'it-IT'
    ],
    defaultLanguage: 'en-US'
  },
  here: {
    apiKeyV3: 'iRhaHB0jRt7Np-iRhaHB0jRt7Np-2bHVzybLZ0fIVGvAixLJ0Y5VLbRX4'
  },
  userPreferenceApiBase: {
    url: 'https://api-dev.zonarsystems.net/alert/v1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
